<template>
  <div class="contact-info">
    <h3 class="contact-info__title">
      Контактная информация
    </h3>
    <div class="contact-info-content">
      <div class="contact-info__item">
        <div class="contact-info-icon-container">
          <Icon
            type="phone"
            to="white"
          />
        </div>
        <dl>
          <dt>Телефон</dt>
          <dd>
            <a href="tel:+74952666892">+7 495 266-68-92</a>
          </dd>
        </dl>
      </div>
      <div class="contact-info__item">
        <div class="contact-info-icon-container">
          <Icon
            type="mail"
            to="white"
          />
        </div>
        <dl>
          <dt>Эл. почта</dt>
          <dd>
            <a href="mailto:support@finsokol.ru"> support@finsokol.ru </a>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactInfo",
  data() {
    return {};
  },
};
</script>

<style src="./contact-info.scss" lang="scss"></style>
