<template>
  <div class="footer">
    <div class="footer-wrapper">
      <ContactInfo />
      <div
        class="footer-text"
        v-html="content"
      />
      <div class="footer-bot">
        <div class="info">
          ©2024 finsokol.ru
        </div>
        <div class="btn-links">
          <p>
            <a
              v-if="!hideLink"
              class="footer__cancel-link cancel-service"
              href="/lk/contracts"
            > Отменить услугу </a>
          </p>
          <div class="img-cards">
            <img
              src="@/assets/images/visa.jpg"
              alt="visa"
            >
            <img
              src="@/assets/images/mir.jpg"
              alt="mir"
            >
            <img
              src="@/assets/images/mc.jpg"
              alt="mc"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactInfo from "./contact-info/contact-info.vue";

export default {
  name: "Footer",
  components: {
    ContactInfo,
  },
  props: {
    variant: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    content: "11",
    hideLink: false,
  }),
  watch: {
    variant: {
      handler: "onVariantChanged",
      immediate: true,
      deep: true,
    },
  },
  methods: {
    onVariantChanged(variant) {
      if (variant === "landing" || variant === "Primary1") {
        this.content = "";
        this.hideLink = true;
        return;
      }

      import(
        /* webpackChunkName: "footer-text-index" */ "./texts/index.js"
      ).then((res) => {
        this.content = res.default.content;
      });
    },
  },
};
</script>

<style lang="scss" src="./footer.scss" />
